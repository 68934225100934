import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import CoachSignUpScreen from "../screens/CoachSignUpScreen/CoachSignUpScreen"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import { ICoachInfoScreenGraph } from "../data/cms/coachInfoScreen"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { ROUTES } from "../navigation/routes"
import { getEnvKey } from "../utils/env"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  datoCmsWebCoachInfoScreenDev?: ICoachInfoScreenGraph
  datoCmsWebCoachInfoScreenStg?: ICoachInfoScreenGraph
  datoCmsWebCoachInfoScreen?: ICoachInfoScreenGraph
  datoCmsWebCoachInfoScreenPrd?: ICoachInfoScreenGraph
}

interface Props {
  data: Data
}

const CoachSignUpPage: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebCoachInfoScreenDev",
    stgKey: "datoCmsWebCoachInfoScreenStg",
    uatKey: "datoCmsWebCoachInfoScreen",
    prdKey: "datoCmsWebCoachInfoScreenPrd",
    defaultKey: "datoCmsWebCoachInfoScreen",
  })
  const content: ICoachInfoScreenGraph = get(props, `data.${contentKey}`)
  if (!content) throw new Error(`datoCmsWebCoachInfoScreen is missing.`)
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation pageName={ROUTES.coachSignUpScreen.name}>
          <PageMetaTags data={props} />
          <CoachSignUpScreen content={content} />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default CoachSignUpPage

export const query = graphql`
  query CoachInfoScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebCoachInfoScreenDev @include(if: $devEnv) {
      ...WebCoachInfoScreenDev
      devSampleLive {
        coachId
        videoId
      }
    }
    datoCmsWebCoachInfoScreenStg @include(if: $stgEnv) {
      ...WebCoachInfoScreenStg
      stgSampleLive {
        coachId
        videoId
      }
    }
    datoCmsWebCoachInfoScreenPrd @include(if: $prdEnv) {
      ...WebCoachInfoScreenPrd
      sampleLive {
        coachId
        videoId
      }
    }
    datoCmsWebCoachInfoScreen @include(if: $uatEnv) {
      ...WebCoachInfoScreen
      uatSampleLive {
        coachId
        videoId
      }
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: forCoachesScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: forCoachesScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: forCoachesScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: forCoachesScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
