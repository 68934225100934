import React from "react"
import TopSection from "./components/TopSection/TopSection"
import PurpleSection from "./components/PurpleSection/PurpleSection"
import SampleAndTeachSection from "./components/SampleAndTeachSection/SampleAndTeachSection"
import DarkSection from "./components/DarkSection/DarkSection"
import { ICoachInfoScreenGraph } from "../../data/cms/coachInfoScreen"
import { YouspiredFooterCTA } from "../../components/AboutYouspired/AboutYouspired"

const CoachSignUpScreen: React.FC<{
  content: ICoachInfoScreenGraph
}> = ({ content }) => (
  <div>
    <TopSection content={content} />
    <PurpleSection content={content} />
    <SampleAndTeachSection content={content} />
    <DarkSection content={content} />
    <YouspiredFooterCTA dark />
  </div>
)

export default CoachSignUpScreen
