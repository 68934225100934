import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  desktopMaxWidthContentCss,
  maxWidthContentCss,
  MobileOnlyMaxWidthContent,
} from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import { StyledCompactText, StyledFancyHeading } from "../TopSection/TopSection"
import {
  StyledIconList,
  StyledListContent,
  StyledListIcon,
} from "../SampleAndTeachSection/SampleAndTeachSection"
import ThumbnailIcon from "../../../../assets/inline-assets/thumbnail.svg"
import HeartIcon from "../../../../assets/inline-assets/heart.svg"
import VideoIcon from "../../../../assets/inline-assets/video.svg"
import AppleIcon from "../../../../ui/icons/AppleIcon/AppleIcon"
import LargePlusHeading from "../../../../ui/typography/LargePlusHeading/LargePlusHeading"
import { StyledBoldHeading } from "../PurpleSection/PurpleSection"
import ActionButton from "../../../../ui/buttons/ActionButton/ActionButton"
import theme from "../../../../ui/theme"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../../../ui/responsive"
import { desktopGridCss } from "../../../../ui/shared"
import {
  getCoachInfoScreenCTAButtonText,
  getCoachInfoScreenCTADestination,
  getCoachInfoScreenCTAHeading,
  getCoachInfoScreenCTASubHeading,
  getCoachInfoScreenGetStartedCTADestination,
  getCoachInfoScreenGetStartedCTAIsAppleButton,
  getCoachInfoScreenGetStartedCTALabel,
  getCoachInfoScreenGetStartedSteps,
  ICoachInfoScreenGraph,
} from "../../../../data/cms/coachInfoScreen"
import { APP_STORE_URL } from "../../../../data/constants"
import { ROUTES } from "../../../../navigation/routes"

const StyledPink = styled.span`
  color: ${theme.colors.primary};
`

const StyledGetStartedList = styled(StyledIconList)`
  overflow: hidden;
  margin-bottom: 29px;

  ${DESKTOP_BREAKPOINT} {
    margin-bottom: 44.5px;
  }

  > li {
    align-items: unset;

    &:last-child {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 18px;
        top: 5px;
        bottom: 0;
        width: 4px;
        background: ${theme.colors.darkGrey};
      }
    }
  }
`

const StyledDarkSection = styled.div`
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  padding-bottom: 85px;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 78px;
    padding-bottom: 112px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 744px;
      width: 425.61px;
      border: 4px solid ${theme.colors.secondary};
      transform: rotate(-30deg) translate(-25%, 0%);
    }
  }
`

const StyledGetStartedSection = styled.section`
  ${MOBILE_BREAKPOINT} {
    padding-top: 58px;
    margin-bottom: 127px;
  }

  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
    ${desktopGridCss};
  }

  > h2 {
    margin-bottom: 45px;
    grid-column: 2 / span 2;
  }
`

export const StyledAppleButton = styled(ActionButton)`
  background: ${theme.colors.white};
  color: ${theme.colors.darkGrey};
`

const StyledApplySection = styled.section`
  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
    margin-top: 202px;
  }
  h2 {
    margin-bottom: 10px;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 23px;
    max-width: 283px;
  }
`

const StyledApplySectionContent = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss};
  }
  ${DESKTOP_BREAKPOINT} {
    grid-column: 3 / span 2;
  }
`

const StyledInfo = styled.div`
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 2;
    margin-top: 22.5px;
  }
`

const DarkSection: React.FC<{
  content: ICoachInfoScreenGraph
}> = ({ content }) => {
  const getStartedSteps = getCoachInfoScreenGetStartedSteps(content)
  return (
    <StyledDarkSection>
      <MobileOnlyMaxWidthContent>
        <StyledGetStartedSection>
          <StyledFancyHeading>
            <StyledCompactText>Get</StyledCompactText>
            <br />
            <StyledPink>started</StyledPink>.
          </StyledFancyHeading>
          <StyledInfo>
            <StyledGetStartedList>
              <li>
                <StyledListIcon dark>
                  <ThumbnailIcon />
                </StyledListIcon>
                <StyledListContent>
                  <h3>{getStartedSteps[0].heading}</h3>
                  <p>{getStartedSteps[0].text}</p>
                </StyledListContent>
              </li>
              <li>
                <StyledListIcon dark>
                  <HeartIcon />
                </StyledListIcon>
                <StyledListContent>
                  <h3>{getStartedSteps[1].heading}</h3>
                  <p>{getStartedSteps[1].text}</p>
                </StyledListContent>
              </li>
              <li>
                <StyledListIcon dark>
                  <VideoIcon />
                </StyledListIcon>
                <StyledListContent>
                  <h3>{getStartedSteps[2].heading}</h3>
                  <p>{getStartedSteps[2].text}</p>
                </StyledListContent>
              </li>
            </StyledGetStartedList>
            <div>
              {getCoachInfoScreenGetStartedCTAIsAppleButton(content) ? (
                <StyledAppleButton
                  href={getCoachInfoScreenGetStartedCTADestination(content)}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppleIcon />
                  {getCoachInfoScreenGetStartedCTALabel(content)}
                </StyledAppleButton>
              ) : (
                <ActionButton
                  as="a"
                  href={getCoachInfoScreenGetStartedCTADestination(content)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getCoachInfoScreenGetStartedCTALabel(content)}
                </ActionButton>
              )}
            </div>
          </StyledInfo>
        </StyledGetStartedSection>
      </MobileOnlyMaxWidthContent>
      {/* <StyledApplySection> */}
      {/*  <StyledApplySectionContent> */}
      {/*    <LargePlusHeading as="h2"> */}
      {/*      {getCoachInfoScreenCTAHeading(content)} */}
      {/*    </LargePlusHeading> */}
      {/*    <StyledBoldHeading> */}
      {/*      {getCoachInfoScreenCTASubHeading(content)} */}
      {/*    </StyledBoldHeading> */}
      {/*    <div> */}
      {/*      <ActionButton */}
      {/*        as={Link} */}
      {/*        to={getCoachInfoScreenCTADestination(content)} */}
      {/*      > */}
      {/*        {getCoachInfoScreenCTAButtonText(content)} */}
      {/*      </ActionButton> */}
      {/*    </div> */}
      {/*  </StyledApplySectionContent> */}
      {/* </StyledApplySection> */}
    </StyledDarkSection>
  )
}

export default DarkSection
